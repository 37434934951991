import { NextPageContext } from "next";
import { useRouter } from "next/router";
import toast from 'react-hot-toast';
import React, { useContext, useEffect, useState } from "react";
import { QueryClient, useMutation, useQueryClient, dehydrate } from '@tanstack/react-query';
import { getAuthenticatedUserQuerie } from "../../types/queries";
import { SignInPayload } from "authory-api-types/dist/types/signup";
import { onV3SignInError, signIn } from "../../api/auth";
import { setCookie } from "nookies";
import { COOKIE_TOKEN_PATH, COOKIE_TOKEN_AGE, CookieKeys } from "../../types/cookie-keys";
import MixpanelTracker from "../../tracking/Mixpanel";
import { getAuthenticatedUserClient, getAuthenticatedUserServer } from "../../utils/getAuthenticatedUser";
import { withGlobalPageSpinner } from "../../components/GlobalPageSpinner";
import { APPLICATION_ROUTES } from "../../types/routes";
import { UserStatus } from "authory-api-types/dist/enums";
import GoogleAnalyticsTracker from "../../tracking/GoogleAnalytics";
import { CookieContext } from "../../context/cookieContext";
import { DEFAULT_LOGGED_IN_PAGE } from "../../redirects/userRedirects";
import { STAGING_ACCESS_COOKIE_NAME } from "../../types/staging-access";
import { V3CreateAccountPageLayout } from "../../pagelayouts/signup/create-account/V3CreateAccountPageLayout";
import { PasswordCredential } from "authory-api-types/dist/types";

let tracked = false;

const LoginPage = () => {
    const router = useRouter();
    const token = useContext(CookieContext);
    const [waitingNavigation, setWaitingNavigation] = useState(false);
    const queryClient = useQueryClient();

    const { authenticatedUser, busy } = getAuthenticatedUserClient(token, router);

    // Paypal flow fails, flash error message
    useEffect(() => {
        if (process.browser && router.query.redirect === `${APPLICATION_ROUTES.SETTINGS_BILLING}?pm=updated`) {
            toast.success("Payment details updated successfully!");
            router.push(APPLICATION_ROUTES.LOGIN, undefined, { shallow: true });
        }
    }, []);

    if (authenticatedUser.isFetched && authenticatedUser.data?.slug && !tracked) {
        tracked = true;
        const googleAnalyticsTracker = new GoogleAnalyticsTracker(authenticatedUser.data);
        googleAnalyticsTracker.trackLogin();
    }

    const signInMutation = useMutation({
        mutationFn: signIn,
        onError: onV3SignInError(toast.error)
    });

    const saveAccountDetails = async (credentials: SignInPayload) => {
        const response = await signInMutation.mutateAsync(credentials);

        if (!response) return;

        setWaitingNavigation(true);

        // Set cookie
        setCookie(null, CookieKeys.auth_token, response.authToken, {
            path: COOKIE_TOKEN_PATH,
            maxAge: COOKIE_TOKEN_AGE
        });

        // Track login event in GA
        const googleAnalyticsTracker = new GoogleAnalyticsTracker(response.user);
        googleAnalyticsTracker.trackLogin();

        // Set authenticated user query data
        queryClient.setQueryData(getAuthenticatedUserQuerie(response.authToken), response.user);

        // Identify mixpanel user
        const mixPanelTracker = new MixpanelTracker(response.user);
        mixPanelTracker.identify();

        const isActive = response.user?.status === UserStatus.Active;
        const isInactive = response.user?.status === UserStatus.Inactive;

        let route = "";

        if (isActive) {

            if (typeof router.query.redirect === "string") {
                const otherArgs = Object.keys(router.query).filter((key) => key !== "redirect");
                route = decodeURIComponent(`${router.query.redirect}${otherArgs.map(item => `&${item}=${router.query[item]}`).join("")}`);
            } else {
                route = DEFAULT_LOGGED_IN_PAGE;
            }

        } else if (isInactive) {
            route = APPLICATION_ROUTES.SIGNUP_DEACTIVATED;

        } else {
            // Go finish signup
            route = APPLICATION_ROUTES.SIGNUP;
        }

        await router.push(route);
        window && window.scrollTo && window.scrollTo(0, 0);
    };

    const signupViaEmail = async (formValues: PasswordCredential) => {
        saveAccountDetails(formValues);
    };

    return withGlobalPageSpinner(
        busy,
        <V3CreateAccountPageLayout
            createAccount={saveAccountDetails}
            signupViaEmail={signupViaEmail}
            mutationLoading={waitingNavigation || signInMutation.isPending}
            login
        />
    );
}

LoginPage.getInitialProps = async (ctx: NextPageContext) => {
    const isServer = !!ctx.req;
    const queryClient = new QueryClient();

    if (isServer) {
        const godModeToken = ctx.query.godmode_token;

        if (godModeToken && !Array.isArray(godModeToken)) {
            setCookie(ctx, CookieKeys.auth_token, godModeToken, {
                path: COOKIE_TOKEN_PATH,
                maxAge: COOKIE_TOKEN_AGE
            });

            const isStaging = process.env.NEXT_PUBLIC_SENTRY_ENV === "staging";

            if (isStaging) {
                setCookie(ctx, STAGING_ACCESS_COOKIE_NAME, "true", {
                    path: COOKIE_TOKEN_PATH,
                    maxAge: COOKIE_TOKEN_AGE
                });
            }
        }

        const redirectLink = ctx.query.redirect;
        const hasRedirect = typeof redirectLink === "string" && !!redirectLink.length;

        // bypass the default redirect system only if there is a redirect request on the url
        const user = await getAuthenticatedUserServer(queryClient, ctx, hasRedirect);

        // if the user is authenticated, we can send him to the intended redirect url
        if (user && hasRedirect && ctx.res) {
            ctx.res.writeHead(302, { Location: redirectLink });
            ctx.res.end();
            return {};
        }

        return {
            dehydratedState: dehydrate(queryClient),
            hideIntercomBubble: true
        };
    } else {
        return {
            hideIntercomBubble: true
        };
    }
}

export default LoginPage;